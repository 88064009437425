.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
   
}
.programhead{
display: flex;
gap: 5rem;
font-weight: bold;
font-size: 3rem;
justify-content: center;
color: white;
text-transform: uppercase;


}


.categories{
    display: flex;
    gap: 1rem;
}
.cat{
    display: flex;
    flex-direction: column;
    background-color: grey;
    padding: 2rem;
    color: white;
    justify-content: space-between;
    border-radius: 5px;
}
.cat:hover{
    background-color: var(--orange);
    cursor: pointer;
    padding: 3rem;
}
.cat>:nth-child(1){
   width: 2rem;
   height: 2rem;
   fill: white;
}
.cat>:nth-child(2){
   font-size: 1.3rem;
   margin-bottom: 1rem;
   font-weight: bold;
 }
 .join{
    font-size: 1rem;
    align-items: center;
    gap: 2rem;

 }
 .join>img{
    height: 1rem;
    width: 1rem;
    margin-top: 1.3rem;
    margin-left: 1rem;
    align-items: center;
 }
 .t{
    width: 30rem;
    height: 10rem;
    right:3rem;
   
 }

 @media screen and (max-width:768px){

   .programs{
      flex-direction: column;
    position: relative;
    margin-top: 44rem;
   }
   .programhead{
      font-size: 1.5rem;
      flex-direction: column;
      justify-content: centre;
      margin-left: 3rem;
   }
   .programhead>span:nth-of-type(2){
     /* margin-left: -3rem;
     margin-right: -3rem; */
     margin-top: -3rem;
     margin-bottom: -3rem;
     margin-left: -3rem;
   }
  .categories{
   display: flex;
   flex-direction: column;
   position: relative;
  
  }
  .join>img{
   position: relative;
 margin-left: 1rem;
  }
.join>span{
   font-size: 1rem;
   margin-top: 1rem;
   
}


.programhead>span:nth-of-type(1){
   margin-left: -1rem;
}
.programhead>span:nth-of-type(3){
   margin-left: -1rem;
}
 }