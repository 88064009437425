.footer{
    position: relative;
   bottom: 5rem;
}
.footer>hr{
    border: 2px solid orange;
    position: relative;
    width:80rem;
}
.foot{
    padding: 1rem 2rem;
    display: flex;
    position: absolute;
    justify-content: centre;
    gap: 4rem;
    top: 3rem;
}
.foot>img{
display: flex;
position: relative;

width: 3rem;
justify-content: space-between;
gap: 40rem;
margin-left: 2.5rem;
}
.foot>:nth-child(2){
    display: flex;
    position: relative;
 left: 60rem;

}
.foot>:nth-child(3){
    display: flex;
    position: relative;
   left:22rem
}
.foot>:nth-child(1){
    display: flex;
    position: relative;
   left:13rem
}
.l{
    width: 5rem;
    display: flex;
    flex-direction: column;
   position: relative;
   top: 4rem;
   left: 40%;
}
.k{
    width: 100rem;
    height: 10rem;
    left:0;
   
}
@media screen and (max-width:768px) {
    .footer{
        display: flex;
        flex-direction: column;
    }
    .foot{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: -10rem;
        transform: scale(1.4);
        top: 8rem;
    }
    .foot>img:nth-of-type(2){
        position: relative;
      margin-left: -45.5rem;
      margin-top: 3rem;
      
    }
    .foot>img:nth-of-type(3){
        position: relative;
      margin-left: -7rem;
      margin-top: 3rem;
      
    }
    .foot>img:nth-of-type(1){
        position: relative;
      margin-left: 1.3rem;
    
      
    }
    
    .z{
        margin-top: 6rem;
        left: -2rem;
    }
    .l{
        margin-top: 10rem;
        left: 1rem;
    }
}