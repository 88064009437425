.plandata{
display: flex;
align-items: centre;
justify-content: center;
gap: 3rem;
}
.Plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 13rem;
}
.Plan:nth-child(2){
    transform: scale(1.2);
    background: var(--planCard);
}
.Plan:hover{
    transform: scarle(1.1);
    background:var(--planCard);
    padding: 1rem;

}
.Plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;

}
.plan>:nth-child(2){
    font-family: 1.4rem;
    font-weight: bold;
    font-style: italic;

}
.Plan>:nth-child(3){
    font-size: 3rem;
}
.Plan>:nth-child(5){
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.features{
  display: flex;
  flex-direction: column; 
  gap: 1rem;
}
.features>img{
    width: 1rem;
}
.S{
    display: flex;
    flex-direction: column;
}.T{
    display: flex;
    font-size: 3rem;
    color:white;
    gap: 4.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.5rem;
    font-weight: bold;
    font-style: italic;

}
.o{
    width: 5rem;
    height: 80rem;
right: 0;
top: 115rem;
   
}
@media screen and (max-width:768px) {

    .S{
        position: relative;
        flex-direction: column;
        margin-top: -5rem;
    }
    .T{
        flex-direction: column;
        font-size: large;
        margin-right: -3rem;
       
    }
    .T>span:nth-of-type(2){
        margin-left: -6rem;
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .plandata{
        flex-direction: column;
        margin-left: 3rem;
        transform: scale(0.9);
        justify-content: space-between;
        margin-top: -5rem;
        justify-content: center;
        align-items: center;
    }
    .plan:nth-child(2){
        position: relative;
       margin-top: 2rem;
       left: 1rem;
    }
}