.Region{
    padding: 0 2rem;
    display: flex;
  gap: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 13rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-r>:nth-child(2){
   width: auto;
   height: 16rem;
   grid-column: 2/4;
}
.left-r>:nth-child(3){
    width: 11.5rem;
    height: 10rem;
    margin-bottom: 6rem;
    
 }
 .left-r>:nth-child(4){
    width: 9em;
    height: auto;
    
 }

 
 

.right-r{
    flex: 3;
    margin-right: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: grey;
  padding: 2rem;
  border-radius: 5px;
  height: 24rem;
  margin-left: 5rem;
  
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
    font-size: 1.5rem;
}
.right-r>div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 3rem;
    color: white;
}
.detail>div>span{
    font-size: 1.5rem;
    gap: 2rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.detail>div>img{
    width: 2rem;
   

}
.right-r:hover{
    background-color: black;
    border-radius: 1rem;
}

.b{
    width: 45rem;
    height: 30rem;
    left: 0;
   
}
    @media  screen and (max-width:768px) {
    
       .Region{
        flex-direction: column;
        margin-top: -2rem;
       }
       .left-r{
        transform: scale(0.7);
        margin-left: -4rem;
       }
       .right-r{
        transform: scale(0.8);
        margin-left: -3rem;
        width: fit-content;
        width: 18rem;
        margin-top: -8rem;
       }

    }